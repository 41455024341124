import * as React from "react";
import { useSearchParams } from "react-router-dom";
import Button from "../atoms/button";

// @ts-ignore
import styles from "../css/form.module.css";

function Form({ queryState }: { queryState: any }) {
  let ref = React.useRef(null);
  let [searchParams, setSearchParams] = useSearchParams();
  let [queryParam, setQueryParam] = queryState;

  const onSubmit = async (e: any) => {
    e.preventDefault();

    if (ref.current.value === "") return;
    setSearchParams({ query: ref.current.value });
    setQueryParam(ref.current.value);
  };

  return (
    <form className={styles.form} onSubmit={onSubmit}>
      <input
        type="text"
        name="search"
        id="search"
        placeholder="Interesting city, ex. Prague"
        className={styles.search}
        defaultValue={queryParam}
        ref={ref}
      />
      <Button variant="secondary" size="big">Search</Button>
    </form>
  );
}

export default Form;
