import { DeletePlaceImageCommand } from "../lib/actions/delete-place-image";

import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import * as React from "react";
import { useCookies } from "react-cookie";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import ScaleLoader from "react-spinners/ScaleLoader";
import { toast } from "react-toastify";
import Button from "../atoms/button";
import Section from "../atoms/section";
// @ts-ignore
import styles from "../css/revision.module.css";
import { getRequest, postRequest } from "../utils/wrapper";
import { addPlaceImage } from "../lib/actions/add-place-image";
import { deletePlaceImage } from "../lib/actions/delete-place-image";
import {ActualFileObject, FilePondFile} from "filepond";
import {API_URL} from "../lib/config";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

async function convertFileToBase64(file: ActualFileObject): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = reader.result as string;
      const base64Data = base64String.split(",")[1];
      resolve(base64Data);
    };
    reader.onerror = (error) => reject(error);
  });
}

async function uploadFileToCdn(base64Photo: string): Promise<string | { error: any }> {
  console.log('Starting the upload process...');
  try {
    const response = await fetch(new URL(`${API_URL}/cdn/base64`), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ base64: base64Photo }),
    });

    const responseText = await response.text();
    console.log('Raw response text:', responseText);

    if (!response.ok) {
      throw new Error(responseText || 'Upload failed');
    }

    const fileReference: string = responseText;
    console.log('Upload successful:', fileReference);

    return fileReference;
  } catch (error) {
    console.error('Upload error:', error);
    return { error };
  }
}

function PlaceUpdatePage(): JSX.Element {
  const [cookies] = useCookies(["token"]);
  const { id } = useParams();
  const navigate = useNavigate();
  const [files, setFiles] = React.useState<FilePondFile[]>([]);
  const [imageUrls, setImageUrls] = React.useState<string[]>([]);

  const formRef = React.useRef<HTMLFormElement>(null);
  const descRef = React.useRef<HTMLTextAreaElement>(null);

  const { isLoading, data } = useQuery(`place/${id}`, () => getRequest(`place/${id}`), {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const initialFiles = data.images.map((image: string) => ({
        source: image,
        options: {
          type: "local",
        },
        file: {
          name: image.split('/').pop(),
          size: 0,
          type: "image/jpeg",
        },
      }));
      setFiles(initialFiles);
      setImageUrls(data.images);
    },
  });

  const handleProcessFile = React.useCallback(async (fieldName: string, file: ActualFileObject, metadata: any, load: (file: ActualFileObject) => void, error: (error: any) => void, progress: (isComputable: boolean, loadedSize: number, totalSize: number) => void, abort: () => void) => {
    try {
      const base64 = await convertFileToBase64(file);
      const uploadResponse = await uploadFileToCdn(base64);

      if (typeof uploadResponse === "string") {
        const imageFilename = uploadResponse;
        const command = {
          placeId: id,
          imageFilename,
          authenticationToken: cookies.token,
        };
        const response = await addPlaceImage(command);
        setImageUrls((prevImageUrls) => Array.from(new Set([...prevImageUrls, ...response.images])));
        load(file);
        progress(true, 100, 100);
        toast.success("Uploaded successfully!");
      } else {
        throw new Error("Failed to upload to CDN");
      }
    } catch (err) {
      console.error("Error uploading file:", err);
      error(`Upload failed: ${err}`);
      toast.error("Failed to upload image");
    }
  }, [id, cookies.token]);

  const handleRemoveImage = React.useCallback(async (file: FilePondFile) => {
    try {
      let imageFilename: string;
      if (typeof file.serverId === 'string') {
        imageFilename = file.serverId.split('/').pop();
      } else if (typeof file.source === 'string') {
        imageFilename = file.source.split('/').pop();
      } else {
        throw new Error("Unable to determine image filename");
      }

      const command: DeletePlaceImageCommand = {
        placeId: id!,
        imageFilename,
        authenticationToken: cookies.token,
      };

      await deletePlaceImage(command);
      setImageUrls((prevImageUrls) => prevImageUrls.filter((imageUrl) => !imageUrl.endsWith(imageFilename)));
      toast.success("Image removed successfully!");
    } catch (err) {
      console.error("Error removing image:", err);
      toast.error("Failed to remove image");
      throw err; // Re-throw the error to be caught in the FilePond onremovefile handler
    }
  }, [id, cookies.token]);


  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const formData = new FormData(formRef.current);
    formData.append("images", JSON.stringify(imageUrls));

    toast.promise(
        postRequest(`place/${id}`, {
          body: formData,
          timeout: false,
          headers: { Authorization: `Bearer ${cookies.token}` },
        }).then((data) => {
          navigate(`/curate/view/${data.revisionId}`);
        }),
        {
          pending: "Processing your update...",
          success: "Yay! Successfully updated!",
          error: "Wow. Something is wrong with your update.",
        }
    );
  };

  if (isLoading && !data) {
    return (
        <div className={styles.hero}>
          <ScaleLoader color={"gray"} loading={isLoading} height={50} width={6} radius={6} margin={3} />
        </div>
    );
  }

  if (data) {
    return (
        <Section padding={8}>
          <div className={styles.hero}>
            <Section className={styles.intro}>
              <h1>{data.name}</h1>
            </Section>
            <form onSubmit={onSubmit} ref={formRef} className={styles.form}>
              <label htmlFor="name" className={styles.label}>
                Place Name:
                <input id="name" name="name" type="text" defaultValue={data.name} className={styles.input} />
              </label>

              <label htmlFor="description" className={styles.label}>
                Description:
                <textarea
                    id="description"
                    name="description"
                    ref={descRef}
                    defaultValue={data.description}
                    placeholder="No description?"
                    className={styles.textarea}
                />
              </label>

              <FilePond
                  files={files}
                  onupdatefiles={setFiles}
                  allowMultiple={true}
                  maxFiles={3}
                  allowRemove={true}
                  server={{
                    process: handleProcessFile,
                    revert: (uniqueFileId, load, error) => {
                      const fileToRemove = files.find(file => file.serverId === uniqueFileId || file.source === uniqueFileId);
                      if (fileToRemove) {
                        handleRemoveImage(fileToRemove)
                            .then(() => load())
                            .catch((err) => error(err.message));
                      } else {
                        error("File not found");
                      }
                    },
                    load: (source, load, error, progress, abort, headers) => {
                      fetch(source)
                          .then(response => response.blob())
                          .then(blob => {
                            load(blob);
                          })
                          .catch(err => {
                            error('Failed to load image');
                          });
                    },
                  }}
                  onremovefile={(error, file) => {
                    handleRemoveImage(file)
                    if (error) {
                      console.error("Error removing file:", error);
                      toast.error("Failed to remove image");
                    }
                  }}
                  name="images"
                  labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
              />

              <Button type="submit" className={styles.button}>
                Update Place
              </Button>
            </form>
          </div>
        </Section>
    );
  }

  return null;
}

export default PlaceUpdatePage;
