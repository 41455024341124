import classNames from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";
import Button from "../atoms/button";
import ButtonsWrapper from "../atoms/buttons";
import Section from "../atoms/section";
// @ts-ignore
import styles from "../css/home.module.css";

const getImageByLocalPathOrCdn = (imageName: string) => {
  // const isProdEnv = process.env.NODE_ENV === "production"
  return `https://yozza.net/${imageName}`;
};

function HomePage() {
  // @ts-ignore
  return (
    <Section padding={10}>
      <div className={styles.hero}>
        <h1 className="center">
          Discover the hidden gems of your city with Yozza.
        </h1>
        <p className="center">
          Find those out-of-the-way spots that locals love, and explore the amazing places in your city with Yozza.
        </p>
        <ButtonsWrapper>
          <Link to="register">
            <Button variant="secondary" size="big">
              Sign up
            </Button>
          </Link>
          <Link to="login">
            <Button size="big">Sign in</Button>
          </Link>
        </ButtonsWrapper>
      </div>
      <Link to="search">
        <img
          className={classNames(styles.bluebox, styles[`bluebox--big`])}
          src={getImageByLocalPathOrCdn("pexels-james-wheeler-1519088.jpg")}
          alt="img0"
          style={{ "--position": "top 0 left 0" }}
        />
      </Link>

      <div className={classNames(styles.homecard)}>
        <img
          className={classNames(styles.bluebox, styles[`bluebox--small`])}
          src={getImageByLocalPathOrCdn("pexels-andrea-piacquadio-3799832.jpg")}
          alt="img1"
        />
        <div className={styles[`homecard__content`]}>
          <h3>Explore the world around you with ease.</h3>
          <p>
            Don’t miss a beat while exploring your city! Yozza’s simple visual interface, one-click point of interest
            descriptions, audio guide, and auto-triggered content make it easy to discover all of the hidden gems in any
            city.
          </p>
        </div>
      </div>

      <div className={classNames(styles.homecard, styles[`homecard--reverse`])}>
        <img
          className={classNames(styles.bluebox, styles[`bluebox--small`])}
          src={getImageByLocalPathOrCdn(
            "pexels-daniel-eliashevsky-6942435.jpg",
          )}
          alt="img2"
          style={{ "--position": "top -15em left 0" }}
        />
        <div className={styles[`homecard__content`]}>
          <h3>Explore cities at yourfingertips.</h3>
          <p>
            Get to know the city like a local with the Yozza mobile app. Our app is designed to help you find those
            hidden gems, making it easy for you to explore the city at your fingertips. Download today and start
            discovering!
          </p>
        </div>
      </div>

      <div className={classNames(styles.homecard)}>
        <img
          className={classNames(styles.bluebox, styles[`bluebox--small`])}
          src={getImageByLocalPathOrCdn("pexels-keira-burton-6147067.jpg")}
          alt="img3"
          style={{ "--position": "top -5em left 0" }}
        />
        <div className={styles[`homecard__content`]}>
          <h3>Share your experiences with the world.</h3>
          <p>
            With Yozza, you can quickly and easily share your experiences with friends, family, and even the whole world
            via social media. Don’t keep your discoveries to yourself—spread the joy and show off your amazing finds!
          </p>
        </div>
      </div>
      <ButtonsWrapper style={{ justifyContent: "center", marginTop: "5rem" }}>
        <Link to="search">
          <Button variant="secondary" size="big">
            Find the hidden gems of your city with Yozza.
          </Button>
        </Link>
      </ButtonsWrapper>
    </Section>
  );
}

export default HomePage;
