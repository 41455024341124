import * as React from "react";
import { useSearchParams } from "react-router-dom";
import Form from "../organisms/form";
import Results from "../organisms/results";
import { useGuide } from "../lib/resources/guide";

export function SearchPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [queryParam, setQueryParam] = React.useState(searchParams.get("query") || "");

  const { status, data, error } = useGuide(queryParam);

  React.useEffect(() => {
    const query = searchParams.get("query") || "";
    setQueryParam(query);
  }, [searchParams]);

  React.useEffect(() => {
    if (queryParam) {
      setSearchParams({ query: queryParam });
    }
  }, [queryParam, setSearchParams]);

  return (
      <div className="hero">
        {!queryParam && (
            <h1 className="h1">
              Search for interesting places near location.
            </h1>
        )}
        <Form queryState={[queryParam, setQueryParam]} />
        {queryParam !== "" && (
            <>
              {status === 'loading' && <div>Loading...</div>}
              {/* @ts-ignore */}
              {status === 'error' && <div>Error: {error.message}</div>}
              {status === 'success' && <Results queryParam={queryParam} />}
            </>
        )}
      </div>
  );
}

export default SearchPage;