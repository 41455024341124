import { API_URL } from "../config";

export type DeletePlaceImageCommand = {
    placeId: string;
    imageFilename: string;
    authenticationToken: string;
}

export async function deletePlaceImage(command: DeletePlaceImageCommand): Promise<void> {
    const url = `${API_URL}/place/${command.placeId}/image/${command.imageFilename}`;
    console.log('Attempting to delete image:', url);

    const response = await fetch(url, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${command.authenticationToken}`
        }
    });

    if (!response.ok) {
        const errorText = await response.text();
        console.error('Delete image failed:', response.status, errorText);
        throw new Error(`Failed to delete photo from place: ${response.status} ${errorText}`);
    }

    console.log('Image deleted successfully');
    return;
}
