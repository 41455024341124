import * as React from "react";
import { useCookies } from "react-cookie";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { postRequest } from "../utils/wrapper.js";

import Button from "../atoms/button";
import ButtonsWrapper from "../atoms/buttons";

function Register({ userState, authenticationState }: { userState: any; authenticationState: any }): JSX.Element {
  const [cookies, setCookie, removeCookie] = useCookies(["cookie-name"]);
  const [isAuthenticated, setIsAuthenticated] = authenticationState;
  const [user, setUser] = userState;
  let emailRef = React.useRef(null);
  let usernameRef = React.useRef(null);
  let passwordRef = React.useRef(null);
  let navigate = useNavigate();

  const onClick = async (e: any) => {
    e.preventDefault();

    let data = await postRequest("user/register", {
      json: {
        email: emailRef.current.value,
        password: passwordRef.current.value,
        username: usernameRef.current.value,
      },
    });

    if (data.token) {
      // Cookies.set('token', data.token)
      setUser({ email: data.email, username: data.username });
      setIsAuthenticated(true);
      setCookie("token", data.token);
      navigate(`/`);
    }

    console.log("reg", data);
  };

  return (
    <>
      <h2 className="center">Sign up</h2>
      <form className="inputForm">
        <input type="email" name="email" id="email" placeholder="email" ref={emailRef} />
        <input type="text" name="username" id="username" placeholder="username" ref={usernameRef} />
        <input type="password" name="password" id="password" placeholder="password" ref={passwordRef} />
        <ButtonsWrapper>
          <Button variant="secondary" onClick={onClick} type={"submit"}>
            Sign up
          </Button>
        </ButtonsWrapper>
      </form>
    </>
  );
}

export default Register;
