import classNames from "classnames";
import * as React from "react";
// @ts-ignore
import styles from "../css/section.module.css";
// import '../css/banner.css'

const Section = (
  { padding, children, className = "", ...rest }:
    & { padding?: 8 | 10; children?: React.ReactNode }
    & React.ComponentProps<"section">,
) => {
  // const Button:React.FC<{variant?: "primary" | "secondary",children?:React.ReactNode} & React.HTMLProps<HTMLButtonElement>> = ({variant="primary",children,...rest}) => {
  console.log(rest);

  return (
    <section className={classNames(styles.section, styles[`section--padding--${padding}`], className)} {...rest}>
      {children}
    </section>
  );
};

export default Section;
