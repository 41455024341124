import {Place} from "../resources/place";
import {API_URL} from "../config";

export type AddPlaceImageCommand = {
    placeId: string;
    imageFilename: string;
    authenticationToken: string;
}

export async function addPlaceImage(command: AddPlaceImageCommand): Promise<Place> {
        const response = await fetch(`${API_URL}/place/${command.placeId}/image/${command.imageFilename}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${command.authenticationToken}`
            },
            body: JSON.stringify({ filename: command.imageFilename })
        });

        if (!response.ok) {
            throw new Error('Failed to add photo to place');
        }

        return response.json();
}