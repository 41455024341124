import ky from "ky";
import * as React from "react";
import { useQuery } from "react-query";
import { Link, useLocation, useParams } from "react-router-dom";
import ScaleLoader from "react-spinners/ScaleLoader";
import { toast } from "react-toastify";
import Button from "../atoms/button.js";
import Section from "../atoms/section";
import { getRequest } from "../utils/wrapper.js";
// @ts-ignore
import styles from "../css/places.module.css";

function PlacePage({ isAuthenticated }: { isAuthenticated?: any }): JSX.Element {
  let location = useLocation();
  const url = location.pathname.includes("/place") ? "place" : "revision";

  const { id } = useParams();
  const {
    isLoading,
    data,
    isFetching,
  } = useQuery(`${url}/${id}`, () => getRequest(`${url}/${id}`));

  if (isLoading && !data) {
    return (
      <div className={styles.hero}>
        <ScaleLoader color={"gray"} loading={isLoading} height={50} width={6} radius={6} margin={3} />
      </div>
    );
  }

  const DeletePlace = (id: string) => {};

  const getGridSize = (length: number) => {
    if (length === 2) {
      return {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridTemplateRows: "1fr",
        gap: "0px 0px",
        gridTemplateAreas: `"img1 img2"`,
      };
    }
    if (length > 2) {
      return {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridTemplateRows: "50% 50%",
        gap: "0px 0px",
        gridTemplateAreas: `"img1 img2"
                    "img1 img3"`,
      };
    }
  };

  if (data) {
    return (
      <div className={styles.hero}>
        {data.images[0] && (
          <Section className={styles.image} style={getGridSize(data.images.length)}>
            {data.images.slice(0, 3).map((image: any, i: number) => (
              <img src={image} alt={"something"} style={{ gridArea: `img${i + 1}` }} />
            ))}
          </Section>
        )}
        <Section padding={8} className={[styles.section, styles["section--center"]].join(" ")}>
          {/* <div> */}
          <h1>{data.name}</h1>
          <p>{data.description}</p>
          <Button
            size="big"
            onClick={() => {
              navigator.clipboard.writeText(window.location.href);
              toast.info("This place is in your clip, try to paste it somewhere.", {
                autoClose: 3000,
              });
            }}
          >
            Share
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="feather feather-share-2"
            >
              <circle cx="18" cy="5" r="3" />
              <circle cx="6" cy="12" r="3" />
              <circle cx="18" cy="19" r="3" />
              <line x1="8.59" y1="13.51" x2="15.42" y2="17.49" />
              <line x1="15.41" y1="6.51" x2="8.59" y2="10.49" />
            </svg>
          </Button>
        </Section>
        {data.images[0] && (
          <Section padding={8} className={styles.section}>
            <ul className={styles.galleryGrid}>
              {data.images.map((image: any, i: number) => (
                <li className={styles.galleryItem} key={image}>
                  <img src={image} alt={"something"} />
                </li>
              ))}
            </ul>
          </Section>
        )}
        {isAuthenticated && (
          <div className={styles.toolbar}>
            <Link to={`/curate/edit/${id}`} className="toolbar">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-edit"
              >
                <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
              </svg>
              Edit
            </Link>
            <button className="toolbar">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-git-merge"
              >
                <circle cx="18" cy="18" r="3" />
                <circle cx="6" cy="6" r="3" />
                <path d="M6 21V9a9 9 0 0 0 9 9" />
              </svg>
              Merge
            </button>
            <button
              className="toolbar"
              onClick={() => DeletePlace(id)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-delete"
              >
                <path d="M21 4H8l-7 8 7 8h13a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z" />
                <line x1="18" y1="9" x2="12" y2="15" />
                <line x1="12" y1="9" x2="18" y2="15" />
              </svg>
              Delete
            </button>
          </div>
        )}
      </div>
    );
  }

  if (data.message) return <h2 className="content">{data.message}</h2>;
}

export default PlacePage;
