import * as React from "react";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery, useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import ScaleLoader from "react-spinners/ScaleLoader";
import Section from "../atoms/section";
// @ts-ignore
import styles from "../css/results.module.css";
import Card from "../molecules/card";
import { getRequest } from "../utils/wrapper.js";

function Results({ queryParam }: { queryParam: string }) {
  let location = useLocation();
  const url = location.pathname.includes("/search") ? "guide" : "near-me";

  const {
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    data: guideData,
    isPlaceholderData,
    isError,
    error,
  } = useInfiniteQuery(
    queryParam.toLowerCase(),
    ({ pageParam = 0 }) =>
      getRequest(
        `${url}/${queryParam}${pageParam ? `?cursor=${pageParam}` : ""}`,
      ),
    {
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.nextToken;
      },
      notifyOnChangeProps: ["data"],
    },
  );

  // console.log(data)

  const { ref, inView } = useInView({
    rootMargin: "200px",
  });

  React.useEffect(() => {
    if (!guideData) {
      window.scrollTo(0, 0);
    }
  }, [isFetching]);

  React.useEffect(() => {
    if (
      inView
      && hasNextPage
      && !isFetchingNextPage
      && guideData.pages.map((page: any) => page?.places).flat().length + 20
        < guideData.pages.at(-1).total
    ) {
      fetchNextPage({
        pageParam: guideData.pages.at(-1).nextPageToken,
      });
    }
  }, [inView]);

  if (isLoading && !guideData) {
    return (
      <ScaleLoader
        color={"gray"}
        loading={isLoading}
        height={50}
        width={6}
        radius={6}
        margin={3}
      />
    );
  }

  // Error Handling
  if (isError) {
    return <h2>Sorry, something went wrong: {error}</h2>;
  }

  const { city } = guideData.pages[0];

  return (
    <>
      <div className={styles.results}>
        <Section padding={10}>
          <div className={styles.subhero}>
            <h2 className="center">
              {city.name}
            </h2>
          </div>
          {city.description && <p>{city.description}</p>}
        </Section>
        <Section padding={8} className={styles.grid}>
          {guideData.pages
            .map((guide: any) => guide?.places)
            .flat()
            .map((place) => <Card place={place} key={place.id} />)}
        </Section>
        <div
          ref={ref}
          style={{
            height: "10px",
            backgroundColor: "transparent",
            width: "80%",
          }}
        />
      </div>
      <ScaleLoader
        color={"gray"}
        loading={isFetchingNextPage}
        height={50}
        width={6}
        radius={6}
        margin={3}
      />
    </>
  );
}

export default Results;
