import * as React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Button from "../atoms/button";
import {useAuth} from "../context/AuthContext";

interface ProfileProps {
  userState: [any, React.Dispatch<React.SetStateAction<any>>];
  onLogout: () => void;
  buttonSize?: "big" | "normal";
}

const Profile: React.FC<ProfileProps> = ({ userState, onLogout, buttonSize }) => {
  const { isAuthenticated } = useAuth();
  const [user, setUser] = userState;
  const navigate = useNavigate();

  const handleLogout = async (e: React.MouseEvent) => {
    e.preventDefault();
    setUser({});
    onLogout();
    navigate('/');
  };

  return isAuthenticated ? (
    <>
      <p>{user.email}</p>
      <Button onClick={handleLogout} data-test="test">
        Log Out
      </Button>
    </>
  ) : (
    <>
      <Link to="register">
        <Button variant="secondary" size={buttonSize}>
          Sign up
        </Button>
      </Link>
      <Link to="login">
        <Button size={buttonSize}>
          Sign in
        </Button>
      </Link>
    </>
  );
};

export default Profile;
