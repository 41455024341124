import * as React from "react";
import * as ReactDOM from "react-dom";
import LoginPage from "./pages/LoginPage";
import NearMe from "./nearMe/index";
import Footer from "./organisms/footer";
import Navbar from "./organisms/navbar";
import HomePage from "./pages/LandingPage";
import SearchPage from "./pages/SearchPage";
import PlacePage from "./pages/PlacePage";
import Register from "./register/index";
import PlaceUpdatePage from "./pages/PlaceUpdatePage";

import useMediaDetector from "./hooks/mediaDetector";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./css/main.css";
import { ErrorBoundary } from "@highlight-run/react";
import { H } from "highlight.run";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { API_URL, HIGHLIGHT_PROJECT_ID } from "./lib/config";
import {AuthProvider, useAuth} from "./context/AuthContext";

const queryClient = new QueryClient();

H.init(HIGHLIGHT_PROJECT_ID, {
  tracingOrigins: true,
  networkRecording: {
    serviceName: "yozza-web",
    enabled: true,
    recordHeadersAndBody: true,
    tracingOrigins: ['localhost', 'api.yozza.net', '127.0.0.1', 'yozza.net'],
    destinationDomains: ["api.yozza.net", "localhost:1337", API_URL],
    enableOtelTracing: true,
    networkRecording: {
      enabled: true,
      recordHeadersAndBody: true,
    },
  },
});

function App() {
  const { isAuthenticated, token, setToken } = useAuth();
  const [user, setUser] = React.useState({});

  const isDarkTheme = useMediaDetector("(prefers-color-scheme: dark)");

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Navbar
            userState={[user, setUser]}
          />
          <div className="content">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="search" element={<SearchPage />} />
              <Route path="near-me" element={<NearMe />} />
              <Route
                path="place/:id"
                element={<PlacePage isAuthenticated={isAuthenticated} />}
              />
              <Route
                path="login"
                element={
                  <LoginPage
                    userState={[user, setUser]}
                  />
                }
              />
              <Route
                path="register"
                element={
                  <Register
                    authenticationState={[isAuthenticated, setToken]}
                    userState={[user, setUser]}
                  />
                }
              />
              <Route path="curate/view/:id" element={<PlacePage />} />
              <Route path="curate/edit/:id" element={<PlaceUpdatePage />} />
            </Routes>
          </div>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme={isDarkTheme ? "dark" : "light"}
          />
          <Footer />

          <ReactQueryDevtools initialIsOpen={false} />
        </BrowserRouter>
      </QueryClientProvider>
    </>
  );
}

function AppWithAuth() {
  return (
    <AuthProvider>
      <App />
    </AuthProvider>
  );
}

ReactDOM.render(
  <ErrorBoundary showDialog>
    <AppWithAuth />
  </ErrorBoundary>,
  document.getElementById("root"),
);
