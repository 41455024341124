import * as React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../atoms/button";
import ButtonsWrapper from "../atoms/buttons";
import { postRequest } from "../utils/wrapper.js";
import {useAuth} from "../context/AuthContext";

function LoginPage({ userState }: { userState: any }): JSX.Element {
  const { setToken } = useAuth();
  const [, setUser] = userState;
  const usernameRef = React.useRef<HTMLInputElement>(null);
  const passwordRef = React.useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const onClick = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!usernameRef.current || !passwordRef.current) {
      return;
    }

    const data = await postRequest("user/login", {
      json: {
        password: passwordRef.current.value,
        username: usernameRef.current.value,
      },
    });

    if (data.token) {
      setUser({ email: data.email, username: data.username });
      setToken(data.token);
      navigate(`/`);
    }
  };

  return (
      <>
        <h2 className="center">Sign in</h2>
        <form className="inputForm" onSubmit={onClick}>
          <input type="text" name="username" id="username" placeholder="username" ref={usernameRef} />
          <input type="password" name="password" id="password" placeholder="password" ref={passwordRef} />
          <ButtonsWrapper>
            <Button variant="secondary" type="submit">
              Sign in
            </Button>
          </ButtonsWrapper>
        </form>
      </>
  );
}

export default LoginPage;
