import mapboxgl from "mapbox-gl";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import ScaleLoader from "react-spinners/ScaleLoader";
import { toast } from "react-toastify";
// @ts-ignore
import styles from "../css/nearMe.module.css";
import { getRequest } from "../utils/wrapper.js";

import { useNavigate } from "react-router-dom";
import Button from "../atoms/button.js";
import ButtonsWrapper from "../atoms/buttons.js";
import Section from "../atoms/section.js";
import useMediaDetector from "../hooks/mediaDetector";
import Card from "../molecules/card";

mapboxgl.accessToken = "pk.eyJ1Ijoia2VpbnNlbGwiLCJhIjoiY2w2bWkyczZ3MGdreTNtbXI0bDZuY2JzMiJ9.edL6bxD7XedcojzIpK0UkA";

function NearMe() {
  const [geolocation, setGeolocation] = useState({
    longitude: undefined,
    latitude: undefined,
  });
  const [placeId, setPlaceId] = useState("");
  const mapContainer = useRef(null);
  const map = useRef(null);
  const layer = useRef(null);
  const [lng, setLng] = useState(-70.9);
  const [lat, setLat] = useState(42.35);
  const [zoom, setZoom] = useState(9);

  const audio = useRef(null);
  const [audioId, setAudioId] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);

  const isMediaTrue = useMediaDetector("(max-width: 1024px)");
  let navigate = useNavigate();

  const {
    data: nearMeData,
  } = useQuery(
    "near-me",
    () =>
      getRequest(
        `near-me?latitude=${geolocation.latitude}&longitude=${geolocation.longitude}&range=30000.0`,
      ),
    {
      enabled: !!geolocation.latitude && !!geolocation.longitude,
      onSuccess(data) {
      },
    },
  );

  const {
    // isLoading,
    isFetching: audioIsFetching,
    data: audioData,
    refetch: audioRefetch,
  } = useQuery(
    `audio/${placeId}`,
    () =>
      getRequest(
        `place/${placeId}/voice`,
      ),
    {
      enabled: false,
      onSuccess(data) {
        audio.current = new Audio("data:audio/ogg;base64," + data.audio);
        audio.current.play();
        setIsPlaying(true);
        console.log(data);
      },
    },
  );

  const selectedPlace = useMemo(
    () => nearMeData?.places.find((place) => place.id === placeId),
    [nearMeData, placeId],
  );

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        console.log(position);
        setGeolocation({
          longitude: position.coords.longitude,
          latitude: position.coords.latitude,
        });
        toast.info(
          JSON.stringify({
            longitude: position.coords.longitude,
            latitude: position.coords.latitude,
          }),
        );
      },
      (error) => {
        if (error.code === 1) {
          toast.error(
            "You need to allow location to fully utilize this page.",
            { autoClose: false },
          );
        }
        console.error(error);
      },
    );
  }, []);

  useEffect(() => {
    if (map.current || !geolocation.longitude || !geolocation.latitude) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [geolocation.longitude, geolocation.latitude],
      zoom: zoom,
      attributionControl: false,
    });
    map.current.loadImage(
      "https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png",
      (error: any, image: any) => {
        map.current.addImage("custom-marker", image);
      },
    );
  });

  useEffect(() => {
    if (nearMeData?.places.length < 0) return;
    if (map.current === null) return;

    console.log(map.current);

    let test = nearMeData.places.map((place: { longitude: any; latitude: any; name: any }) => ({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [place.longitude, place.latitude],
      },
      properties: {
        title: place.name,
      },
    }));

    console.log(test);

    map.current.addSource("points", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: test,
      },
    });

    map.current.addLayer({
      id: "points",
      type: "symbol",
      source: "points",
      layout: {
        "icon-image": "custom-marker",
        // get the title name from the source's "title" property
        "text-field": ["get", "title"],
        "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
        "text-offset": [0, 1.25],
        "text-anchor": "top",
      },
    });
  }, [nearMeData?.places]);

  return (
    <div className="hero">
      <h1 className="h1">Live Tracking</h1>
      {nearMeData && nearMeData.places.length === 0 && (
        <>
          <h1>No place to visit, sorry</h1>
        </>
      )}

      <Section padding={8} className={styles.mainView}>
        {selectedPlace && (
          <div className={styles.placePreview}>
            <h3 className="h3">{selectedPlace.name}</h3>
            <img
              src={selectedPlace.images?.[0]
                || "https://picsum.photos/seed/picsum/350/350"}
              alt=""
            />
            <div className={styles.desc}>
              <p>{selectedPlace.description}</p>
              <ButtonsWrapper>
                {selectedPlace.description && (
                  <Button
                    size="big"
                    variant="secondary"
                    onClick={() => {
                      if (audio.current === null || placeId !== audioId) {
                        setAudioId(placeId);
                        audioRefetch();
                        if (isPlaying) {
                          audio.current.pause();
                          setIsPlaying(false);
                        }
                      } else {
                        if (!isPlaying) {
                          audio.current.play();
                          setIsPlaying(true);
                        } else {
                          audio.current.pause();
                          setIsPlaying(false);
                        }
                      }
                    }}
                  >
                    Listen this description

                    {audioIsFetching
                      ? (
                        <ScaleLoader
                          color={"gray"}
                          loading={audioIsFetching}
                          height={17}
                          width={4}
                          radius={4}
                          margin={2}
                        />
                      )
                      : isPlaying && placeId === audioId
                      ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-pause"
                        >
                          <rect x="6" y="4" width="4" height="16" />
                          <rect x="14" y="4" width="4" height="16" />
                        </svg>
                      )
                      : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-play"
                        >
                          <polygon points="5 3 19 12 5 21 5 3" />
                        </svg>
                      )}
                  </Button>
                )}
                <Button size="big">Next</Button>
              </ButtonsWrapper>
            </div>
          </div>
        )}

        <div
          ref={mapContainer}
          className={styles.mapContainer}
        />
        <ul className={styles.ul}>
          {nearMeData?.places.map((place) => (
            <li key={place.id} className={styles.li}>
              <img
                src={place.images?.[0] || "https://picsum.photos/seed/picsum/350/350"}
                alt={place.name}
                loading="lazy"
              />
              <p>{place.name}</p>
              <Button
                onClick={() => {
                  setPlaceId(place.id);

                  if (isMediaTrue) {
                    navigate(`/place/${place.id}`);
                  } else {
                    map.current.flyTo({ center: [place.longitude, place.latitude], zoom: 17 });
                  }
                }}
              >
                Open
              </Button>
            </li>
          ))}
        </ul>
      </Section>
    </div>
  );
}

export default NearMe;
