import ky, { HTTPError } from "ky";
import { API_URL } from "../lib/config";

export const api = ky.extend({
  prefixUrl: API_URL,
  timeout: false,
});

export default api;

export async function getRequest(url: string) {
  try {
    const token = localStorage.getItem('authToken');
    const headers: Record<string, string> = {};
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    const data: any = await api(url, { headers }).json();
    console.log({ data });

    return data;
  } catch (error) {
    console.error(
        error,
        error instanceof HTTPError,
        error instanceof HTTPError && error.response,
    );
    if (error instanceof HTTPError && error.response) {
      return { error: await error.response.json() };
    }
    return error;
  }
}

export async function postRequest(url: string, opt: any) {
  try {
    const token = localStorage.getItem('authToken');
    if (token) {
      opt.headers = {
        ...opt.headers,
        'Authorization': `Bearer ${token}`,
      };
    }

    const data: any = await api.post(url, opt).json();
    console.log({ data });

    return { ...data };
  } catch (error) {
    console.error(
        error,
        error instanceof HTTPError,
        error instanceof HTTPError && error.response,
    );
    if (error instanceof HTTPError && error.response) {
      return [null, await error.response.json()];
    }
    return { error };
  }
}
