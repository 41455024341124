import * as React from "react";
import { Link } from "react-router-dom";
import ButtonsWrapper from "../atoms/buttons";
// @ts-ignore
import styles from "../css/navbar.module.css";
import useMediaDetector from "../hooks/mediaDetector";
import Profile from "./profile";
import {useAuth} from "../context/AuthContext";

const ConditionalWrapper = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : children;

function Navbar({ userState }: { userState: any }) {
    const { isAuthenticated, setToken } = useAuth();
    const isMediaTrue = useMediaDetector("(max-width: 768px)");
    const dialogRef = React.useRef(null);

    const onClick = () => {
        dialogRef.current.showModal();
    };

    const onClickDialog = (e) => {
        dialogRef.current.close();
    };

    const handleLogout = () => {
        setToken(null);
        // Additional logout logic if needed
    };

    return (
        <nav>
            <img
                className={styles.img}
                src="https://yozza.net/Yozza_logo_1_adobe_express.svg"
                alt="logo"
            />
            <ConditionalWrapper
                condition={isMediaTrue}
                wrapper={children => (
                    <div>
                        <button onClick={onClick}>click me</button>
                        <dialog ref={dialogRef} onClick={onClickDialog}>{children}</dialog>
                    </div>
                )}
            >
                <ol className={styles.navList}>
                    <li>
                        <Link to="search">Search</Link>
                    </li>
                    <li>
                        <Link to="near-me">Near me</Link>
                    </li>
                </ol>
                <ButtonsWrapper className={styles.profile}>
                    <Profile
                        isAuthenticated={isAuthenticated}
                        onLogout={handleLogout}
                        userState={userState}
                        buttonSize={isMediaTrue ? "big" : "normal"}
                    />
                </ButtonsWrapper>
            </ConditionalWrapper>
        </nav>
    );
}

export default Navbar;
