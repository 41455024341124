import * as React from "react";
import { Link } from "react-router-dom";
import Button from "../atoms/button.js";

import { toast } from "react-toastify";
import ButtonsWrapper from "../atoms/buttons.js";
// @ts-ignore
import styles from "../css/card.module.css";

function Card({ place }: { place: any }) {
  return (
    <div
      className={styles.card}
      key={place.id}
      style={{ backgroundImage: `url(${place.images?.[0] || "https://picsum.photos/seed/picsum/350/350"})` }}
    >
      <ButtonsWrapper className={styles["card_buttons"]}>
        <Button
          size="small"
          onClick={() => {
            navigator.clipboard.writeText(`${window.location.origin}/place/${place.id}`);
            toast.info("This place is in your clip, try to paste it somewhere.", {
              autoClose: 3000,
            });
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-share-2"
          >
            <circle cx="18" cy="5" r="3" />
            <circle cx="6" cy="12" r="3" />
            <circle cx="18" cy="19" r="3" />
            <line x1="8.59" y1="13.51" x2="15.42" y2="17.49" />
            <line x1="15.41" y1="6.51" x2="8.59" y2="10.49" />
          </svg>
        </Button>
        <Button size="small">
          <Link to={`../place/${place.id}`} className={styles.link}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-arrow-right"
            >
              <line x1="5" y1="12" x2="19" y2="12" />
              <polyline points="12 5 19 12 12 19" />
            </svg>
          </Link>
        </Button>
      </ButtonsWrapper>
      <div className={styles.card_content}>
        <h3>{place.name}</h3>
      </div>
    </div>
  );
}

export default Card;
