import { useEffect, useState } from "react";

export default function useMediaDetector(mediaQuery: string) {
  const getCurrentMedia = () => window.matchMedia(mediaQuery).matches;

  const [isMediaTrue, setIsMediaTrue] = useState(getCurrentMedia());

  const mediaQueryListener = (e: any) => setIsMediaTrue(e.matches);

  useEffect(() => {
    const windowMediaQuery = window.matchMedia(mediaQuery);
    windowMediaQuery.addEventListener("change", mediaQueryListener);
    return () => windowMediaQuery.removeEventListener("change", mediaQueryListener);
  }, []);

  return isMediaTrue;
}
