import useSWR, { SWRResponse } from "swr";
import { Place } from "./place";
import { City } from "./city";
import { API_URL } from "../config";

const fetcher = (url: string) => fetch(url).then((res) => res.json());

export interface Guide {
	/** Information about selected city. */
	city: City;

	/**
	 * Wikipedia-agregated description about specific city.
	 */
	description: string;

	/** Places related to specific city. */
	places: Place[];
	/**
	 * Represents token for next page, this field is hidden when there is no more pages
	 * @example "cl8xjffr838052dmc6jfi1yd0"
	 */
	nextPageToken?: string;
}

export function useGuide(query: string): {
	data: Guide | undefined;
	error: any;
	isLoading: boolean;
	isValidating: boolean;
	mutate: (
		data?: Guide | Promise<Guide>,
		shouldRevalidate?: boolean,
	) => Promise<Guide | undefined>;
	status: string;
} {
	const { data, error, isValidating, mutate } = useSWR<Guide>(
		query ? `${API_URL}/guide/search?query=${query}` : null,
		fetcher,
		{
			onSuccess: (data) => {
				console.log("Data fetch successful:", data);
			},
			onError: (error) => {
				console.error("Data fetch failed:", error);
			},
			onLoadingSlow: () => {
				console.warn("Data fetch is taking longer than expected.");
			},
		},
	);

	console.log("Fetching data with query:", query);

	return {
		isLoading: !data && !error && isValidating,
		isValidating,
		mutate,
		data,
		error,
		status: !query
			? "idle"
			: !data && !error
				? "loading"
				: error
					? "error"
					: "success",
	};
}
