import * as React from "react";
// @ts-ignore
import styles from "../css/footer.module.css";

function Footer() {
  return (
    <div className={styles.footer}>
      <ol>
        {/* <li>Product</li> */}
        {/* <li>Company</li> */}
        {/* <li>Social</li> */}
        {/* <li>Social</li> */}
        {/* <li>Something</li> */}
      </ol>
      <ol>
        {/* <li>Product</li> */}
        {/* <li>Company</li> */}
        {/* <li>Social</li> */}
        {/* <li>Social</li> */}
        {/* <li>Something</li> */}
      </ol>
      <ol>
        {/* <li>Product</li> */}
        {/* <li>Company</li> */}
        {/* <li>Social</li> */}
        {/* <li>Social</li> */}
        <li></li>
      </ol>
      <ol>
        {/* <li>Product</li> */}
        {/* <li>Company</li> */}
        {/* <li>Social</li> */}
        {/* <li>Social</li> */}
        <li></li>
      </ol>
    </div>
  );
}

export default Footer;
