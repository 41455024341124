import classNames from "classnames";
import * as React from "react";
import styles from "../css/button.module.css";

const Button = (
  { variant = "primary", size = "normal", weight = 400, className = "", children, ...rest }: {
    variant?: "primary" | "secondary";
    size?: "small" | "normal" | "big";
    weight?: 400 | 600;
    children?: React.ReactNode;
  } & React.ComponentProps<"button">,
) => {
  return (
    <button
      className={classNames(
        styles.button,
        styles[`button--variant--${variant}`],
        styles[`button--size--${size}`],
        styles[`button--weight--${weight}`],
        className,
      )}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
