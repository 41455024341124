import classNames from "classnames";
import * as React from "react";
// @ts-ignore
import styles from "../css/buttons.module.css";

const ButtonsWrapper = (
  { children, className = "", ...rest }: { children?: React.ReactNode } & React.ComponentProps<"div">,
) => {
  return (
    <div className={classNames(styles.buttonsWraper, className)} {...rest}>
      {children}
    </div>
  );
};

export default ButtonsWrapper;
